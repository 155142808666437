/* new css */
.sidebar {
    min-height: calc(100vh) !important;
}
.sidebar .sidebar-brand-wrapper, .sidebar, .navbar .navbar-brand-wrapper, .navbar{
    /* background: linear-gradient(81.33deg,#1f2421 7.09%,#546158 173.02%) !important; */
    /* background: #1c091e !important; */
    background: #000 !important;
    border-right: 1px solid #32fefb40;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
.sidebar .nav .nav-item.nav-category .nav-link, .sidebar .nav .nav-item .nav-link, .sidebar .nav.sub-menu .nav-item .nav-link, .navbar .navbar-menu-wrapper .navbar-toggler{
    color: #fff;
    font-weight: 500;
}
.sidebar .nav:not(.sub-menu) > .nav-item:hover:not(.nav-category):not(.account-dropdown) > .nav-link{
    background: #70fdfc !important;
}
.sidebar .nav:not(.sub-menu) > .nav-item:hover:not(.nav-category):not(.account-dropdown) > .nav-link .menu-title{
    color: #000;
}
.sidebar .nav .menu-items:nth-child(5n+3) .nav-link .menu-icon i{
    /* color: #8bed0f; */
    /* color: #cf00e0; */
    color: #fff;
}
.card{
    /* background: #162019; */
    /* background: #121111; */
    background: #000;
    border: 1px solid #8080804f;
}
.table, .text-muted, th.sortable{
    color: #fff !important;
}
.footer a{
    /* color: #71228a !important; */
    color: #70fdfc !important;
}
.form-control::placeholder{
    color: #ffffffbd !important;
}
.form-control{
    /* background: #ffffff24 !important; */
    background: #47fefb05 !important;
}
.form-control:disabled, .form-control[readonly] {
    color: #c7c7c7 !important;
}
.sidebar .nav.sub-menu .nav-item .nav-link:hover {
    /* color: #3cdb6a; */
    color: #70fdfc;
}
.sidebar .sidebar-brand-wrapper .sidebar-brand.brand-logo-mini img
{
    max-width: 45px;
    margin-top: 4px !important;
}
select option {
    background: #353f3a !important;
}
.icon.icon-box-success {
    color: #00d25b !important;
    /* background: #00d25b !important; */
}
.auth .brand-logo {
    text-align: center;
}
/* new end */










.sidebar .sidebar-brand-wrapper .sidebar-brand.brand-logo-mini img {
    width: calc(90px - 50px);
}

.navbar .navbar-brand-wrapper .navbar-brand.brand-logo-mini img {
    width: calc(90px - 50px);
}

.sidebar .sidebar-brand-wrapper .sidebar-brand img{
    width: calc(244px - 100px) !important;
    margin-top: 16px !important;
}
.btn-success{
    background: #70fdfc !important;
    border: 1px solid #70fdfc !important;
    color: #0a0a0a !important;
}
.userlistss tbody tr td{
   padding: 20px 12px;
}
.form-control:focus {
    color: #fff !important;
    border: 1px solid rgb(71 254 251 / 14%) !important;
}
/* .asrt-page-length select{
    background: transparent !important;
    border: 1px solid #413e47 !important;
} */
select {
    cursor: pointer;
}

select option {
    cursor: pointer;
}

input[type="search"]::-webkit-search-cancel-button {
    cursor: pointer !important;
    color: #fff !important;
}

.asrt-pagination li {
    cursor: pointer;
}

.passwordinput .form-control {
    background-color: #191c24;
}
.passwordinput .btn-outline-primary {
    color: #70fdfc;
    border-color: #494a51;
    padding: 10px 10px;
}
.passwordinput .btn-outline-primary:hover {
    background-color: transparent !important;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus {
    box-shadow: unset !important;
}
/* .footer a{
    color: #886abe !important;
} */
.asrt-page-length .input-group-addon{
    background: unset !important;
}
.asrt-page-length select{
    color: #fff !important;
}
.input-group-prepend span{
    color: #fff !important;
}
.asrt-table-foot{
    margin-top: 2%;
}
.page-item.disabled .page-link {
    color: #fff !important;
    pointer-events: none;
    cursor: auto;
    background-color: #fff0 !important;
    border-color: #fff !important;
}
.page-item.disabled .page-link:hover{
    background-color: #70fdfc !important;
    color: #000 !important;
    border-color: #70fdfc !important;
    border: 1px solid #70fdfc;
}
.navbar .dropdown-menu-right {
background-color: #000 !important;
border: 1px solid #8080805e !important;
}
.as-react-table .pagination li:hover a {
    background-color: #70fdfc !important;
    color: #000 !important;
}
.page-link {
    background-color: #00000000 !important;
    border: 1px solid #fff;
}
.page-link input{
    background: unset !important;
    color: #fff !important;
}
.allbtn{
    /* background: #3cdb6a;
    border: 1px solid #3cdb6a;
    color: #000; */
    background: #70fdfc;
    border: 1px solid #70fdfc;
    color: #0a0a0a !important;
    font-weight: 500;
    padding: 10px 16px;
}
input[type="file"] {
    background: #70fdfc00;
    border: 1px solid #70fdfc1a;
    color: #fff;
}
.sidebar .nav .nav-item.active > .nav-link:before{
    background: #70fdfc !important;
}
.navbar .navbar-menu-wrapper {
    border-bottom: 1px solid #32fefb40;
    box-shadow: 0 3px 10px #32fefb26;
}
.table thead th{
   line-height: 21px;
}
.allbtnlogout{
    background: red;
    border: 1px solid red;
    padding: 6px 14px;
    color: #fff;
    border-radius: 6px;
}
.allbtnlogout:hover{
    background: transparent;
    border: 1px solid red;
    color: red;
}
.allbtns{
    padding: 8px 16px !important; 
}
.css-yt9ioa-option {
    color: black !important; 
    /* background-color: #886abe !important; */
}
.css-1n7v3ny-option {
    /* background-color: #886abe !important; */
}

.NFT.IDT{
    width: 580px !important;
    max-width: 580px !important;
    word-wrap: break-word !important;
}
.NFT.IDT p{
    line-height: normal;
    font-weight: 400;
}

.css-6c09z3-option{
    color:#000 !important;
}
.css-1gj9k8-option{
    background-color: unset !important;
    color: #000 !important;
}
.css-zjsb6u-option{
    background-color: #886abe !important;
}

.css-1n7v3ny-option {
    background-color: unset !important;
    color: black !important;
   /* background-color: transparent !important;
   color: black !important; */
}
.css-1pahdxg-control:hover{
    border-color: white !important;
}
.css-9gakcf-option {
    background-color: #886abe !important;
}
/* .css-9gakcf-option {
    background-color: transparent !important;
    color: black !important;
} */

.allbtn:hover{
    background: transparent;
    /* border: 1px solid #3cdb6a;
    color: #3cdb6a !important; */
    border: 1px solid #70fdfc;
    color: #70fdfc !important;
}
.icon.icon-box-success {
    background: #70fdfc12 !important;
}
.icon .icon-item {
    color: #fff;
}
.ReactModal__Overlay {
    background-color: #00000091 !important;
}
.ReactModal__Content{
    border: 1px solid #808080a8 !important;
    background: rgb(11 12 15) !important;
    border-radius: 16px !important;
}
.ReactModal__Content h2{
    color: rgb(136 106 190) !important;
}
.closebtn{
    position: absolute;
    right: 8px;
    top: 4px;
    font-size: 19px;
    font-weight: 500;
}
.closebtn:hover{
   color: #886abe;
}
.table-responsive{
    padding-bottom: 2%;
    overflow-x: auto !important;
}
/* .asrt-table-body {
    overflow: auto;
    padding-bottom: 2%;
} */
.table-responsive::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

.table-responsive::-webkit-scrollbar
{
	width: 8px !important;
    height: 9px !important;
	background-color: #F5F5F5;
}

.table-responsive::-webkit-scrollbar-thumb
{
    border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #886abe;
}
.faq .as-react-table{
    padding-bottom: 2%;
    overflow-x: auto !important;
}
.faq .as-react-table::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

.faq .as-react-table::-webkit-scrollbar
{
	width: 8px !important;
    height: 9px !important;
	background-color: #F5F5F5;
}

.faq .as-react-table::-webkit-scrollbar-thumb
{
    border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #886abe;
}
.sidebar .nav .nav-item .nav-link i.menu-arrow:before {
  display: none;
}
.dash-token{
    color: #886abe !important;
}
.editservice{
    font-size: 15px;
}
.sidebar .nav.sub-menu .nav-item .nav-link {
    white-space: normal;
    line-height: 20px;
}
/* th.sortable {
    color: #886abe !important;
    font-weight: 600 !important;
} */

@media only screen and (min-width: 992px) {
    .sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapse, .sidebar-icon-only .sidebar .nav .nav-item.hover-open .nav-link .menu-title{
        background: #000;
    }
}


@media only screen and (max-width: 767px) {
     .table-responsive::-webkit-scrollbar
{
	width: 6px !important;
    height: 6px !important;
	background-color: #F5F5F5;
}
.faq .as-react-table::-webkit-scrollbar
{
	width: 6px !important;
    height: 6px !important;
	background-color: #F5F5F5;
}
    .table-responsive{
        padding-bottom: 8%;
    }
    .faq .as-react-table{
        padding-bottom: 8%;
    }
    .pagination {
        justify-content: unset !important;
    }
    .pagination li{
        font-size: 11px !important;
        margin-top: 10px;
    }
    .table_filter input{
       margin-top: 14px;
    }
   .asrt-page-length .input-group-text{
       font-size: 11px;
    }
    .asrt-page-length select.form-control {
        padding: 0.4375rem 0.45rem !important;
        width: 53px !important;
        font-size: 12px !important;
    }
    .asrt-table-head .text-right{
        text-align: left !important;
    }
    .asrt-table-foot>div:first-child {
       font-size: 12px;
       margin-top: 6px;
    }
  }
  .navbar.loginnavbar{
    left: 0px !important;
}
.btn-primary:not(.btn-light):not(.btn-secondary):focus, .custom-file .custom-file-label:not(.btn-light):not(.btn-secondary):focus:after, .btn-primary:not(.btn-light):not(.btn-secondary):active, .custom-file .custom-file-label:not(.btn-light):not(.btn-secondary):active:after {
    background: #886abe !important;
    border-color: #886abe !important;
    color: #fff !important;
}

.referralpages .btnsec{
    display: flex;
    align-items: center;
    margin-top: 8px;
}
.referralpages .add_btnsec{
    margin-left: 15px;
    margin-top: 10px;
}
.referralpages .allbtn{
    width: 100px;
}

@media only screen and (max-width: 767px) {
    .referralpages .add_btnsec{
        margin-left:12px;
    }
    .referralpages .btnsec{
        margin-bottom: 15px;
    }
}
